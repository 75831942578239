// Color system

$blue: #1b1a4c;
$dark-blue: #020038;
$purple: #9758b0;
$dark-purple: #7236a6;
$light-purple: #f5eff8;
$brown: #efd193;
$light-brown: #fdebc7;

$primary: $purple;

// Spacing

$spacer: 1rem;

// Body

$body-color: $dark-blue;

// Links

$link-decoration: underline;
$link-hover-color: $dark-purple;
$link-hover-decoration: none;

// Paragraphs

$paragraph-margin-bottom: $spacer * 2;

// Typography

$font-family-sans-serif: 'filson-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';

$font-size-base: 1rem;

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: $font-weight-bold;

$line-height-base: 1.666666;

$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.666666;
$h4-font-size: $font-size-base * 1.111111;

$headings-margin-bottom: $spacer * 2;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.333333;

$display1-size: $font-size-base * 3.333333;

$display1-weight: $font-weight-bold;

$display-line-height: 1.4;

$text-muted: fade-out($body-color, 0.5);

$lead-font-size: $font-size-base * 1.111111;
$lead-font-weight: $font-weight-normal;
$lead-line-height: 1.8;

// Components

$border-radius: $font-size-base * 1.666666;
$border-radius-lg: $border-radius;

// Buttons

$input-btn-padding-y: $font-size-base * 0.5;
$input-btn-padding-x: $font-size-base * 1.111111;
$input-btn-line-height: 1.56;

$input-btn-padding-y-lg: $font-size-base * 0.834;
$input-btn-padding-x-lg: $font-size-base * 2.333333;
$input-btn-font-size-lg: $font-size-base;
$input-btn-line-height-lg: 1.56;

$btn-font-weight: $font-weight-bold;

@import '~bootstrap/scss/bootstrap';

@mixin bg-img($url) {
  background-image: url($url);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

html {
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spacing-small {
  margin-bottom: $spacer * 2.666666;
}

.spacing-medium {
  margin-bottom: $spacer * 4;
}

.spacing-large {
  margin-bottom: $spacer * 6;
}

.spacing-x-large {
  margin-bottom: $spacer * 8.666666;
}

.lead {
  line-height: $lead-line-height;
}

h1,
.h1 {
  margin-bottom: $spacer * 2.666666;

  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 2.666666;
  }
}

h3,
.h3 {
  margin-bottom: $spacer * 1.333333;
  line-height: 1.6;
}

h4,
.h4 {
  line-height: 1.8;
  font-weight: $font-weight-normal;
  margin-bottom: 0;
}

.display-1 {
  margin-bottom: $spacer * 2.666666;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 2;
  }
}

p {
  letter-spacing: 0.1px;
}

.dot {
  &:after {
    content: '.';
    color: $primary;
  }
}

.block-xs {
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.text-center-md {
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.btn-primary {
  @include button-variant($primary, $primary, $white, $primary);

  &:hover {
    color: $primary;

    &.btn-hover-no-border {
      border-color: $white;
    }
  }
}

.btn-primary-inverted {
  @include button-outline-variant($primary);
  border: $white;
  background-color: $white;
}

.btn-transparent {
  @include button-outline-variant($white, $primary);

  .mobile-menu-content & {
    @include button-outline-variant($primary);
  }
}

.logo {
  img {
    height: 48px;
  }
}

.page-section-header {
  padding-top: $spacer * 2.666666;
  padding-bottom: $spacer * 8;

  @include media-breakpoint-down(xs) {
    padding-top: $spacer;
    padding-bottom: $spacer * 6.666666;
  }

  &.simple {
    padding-bottom: $spacer;
  }

  &.clouds-bg {
    @include bg-img('../assets/bg-images/clouds.jpg');
  }

  &.sky-bg {
    @include bg-img('../assets/bg-images/sky.jpg');
    color: $white;
  }

  .logo img {
    @include media-breakpoint-down(xs) {
      height: 42px;
    }
  }

  h1 {
    margin-top: $spacer * 6.666666;

    @include media-breakpoint-down(xs) {
      margin-top: $spacer * 4.6;
    }
  }

  .lead {
    margin-bottom: $spacer * 3.333333;
  }

  .mobile-menu-open,
  .mobile-menu-close {
    img {
      height: 28px;
    }
  }

  .header-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zindex-modal;
    background-color: $white;

    &:not(.show) {
      display: none;
    }

    .mobile-menu-wrapper {
      padding-top: $spacer * 2.666666;

      @include media-breakpoint-down(xs) {
        padding-top: $spacer;
      }
    }

    .mobile-menu-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.page-section-main {
  padding-top: $spacer * 6;
  padding-bottom: $spacer * 5;
}

.page-section-cta-large {
  padding: $spacer * 9.333333 0;

  @include media-breakpoint-down(xs) {
    padding-top: $spacer * 5.333333;
    padding-bottom: $spacer * 5.333333;
  }

  &.candles-bg {
    @include bg-img('../assets/bg-images/candles.jpg');
  }

  &.tree-bg {
    @include bg-img('../assets/bg-images/tree.jpg');
  }
}

.page-section-cta-small {
  padding-top: $spacer * 4;
  padding-bottom: $spacer * 4.333333;
  background-color: $blue;
  color: $white;
}

.page-section-footer {
  padding: $spacer * 5 0;
  background-color: $dark-blue;
  color: $white;

  a {
    color: $brown;

    &:hover {
      color: $light-brown;
    }
  }

  hr {
    margin-top: $spacer * 3;
    margin-bottom: $spacer * 5;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .social-icons {
    > * {
      margin-left: 19px;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .copyright {
    margin-top: $spacer * 3.333333;
    font-size: calc($font-size-base / 1.125);
    color: rgba(255, 255, 255, 0.6);
  }

  @include media-breakpoint-down(xs) {
    .logo {
      width: 100%;
      text-align: center;
    }

    .footer-menu {
      flex-wrap: wrap;
      text-align: center;

      > * {
        width: 100%;
      }

      .social-icons {
        padding: $spacer * 2.666666 0;
      }
    }

    .copyright {
      text-align: center;
    }
  }
}

.steps {
  margin: $spacer * 4 0;

  .step-numbers {
    position: relative;
    margin-bottom: $spacer * 1.5;

    .line {
      position: absolute;

      img {
        @include media-breakpoint-down(lg) {
          width: calc(100% - 25px);
        }

        @include media-breakpoint-down(md) {
          width: calc(100% - 180px);
        }
      }
    }

    .step-number-1,
    .step-number-3 {
      margin-top: 10px;

      @include media-breakpoint-down(lg) {
        margin-top: 0;
      }

      @include media-breakpoint-down(md) {
        margin-top: -10px;
      }
    }

    .step-number-2 {
      margin-top: -30px;
    }
  }

  .step-number {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    background-color: $light-purple;
    color: $purple;
    font-weight: $font-weight-bold;
    font-size: $font-size-base * 2;
    border-radius: 50%;
  }

  .step-icon {
    margin-bottom: $spacer;

    @include media-breakpoint-down(sm) {
      &.step-icon-1 {
        margin-top: $spacer;
      }

      &.step-icon-2 {
        margin-top: $spacer * 1.8;
      }

      &.step-icon-3 {
        margin-top: $spacer;
      }
    }
  }
}

.benefits-icon {
  margin-top: $spacer;
  margin-bottom: $spacer * 2;
}

ul {
  list-style-image: url('../assets/list_bullet.svg');

  li {
    padding-left: 10px;
  }
}

.welcome-modal {
  .modal-content {
    border-radius: 0.3rem;
  }

  .modal-header {
    img {
      height: 2rem;
    }

    .close {
      font-size: 2rem;
    }
  }

  .modal-footer {
    .btn {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

@mixin alert-bar {
  position: fixed;
  left: 0;
  width: 100%;
  margin: 0 !important;
  padding: 0.945rem 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  background-color: $dark-blue;
  color: $white;

  a {
    color: $brown;

    &:hover {
      color: $light-brown;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.cookie-bar {
  @include alert-bar;
  bottom: 0;

  .btn {
    padding-left: 2rem;
    padding-right: 2rem;

    @include media-breakpoint-down(xs) {
      display: block;
      width: 100%;
      margin-top: 0.5rem;
    }
  }
}
